/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

// import axios from 'axios';
// window.axios = axios;
// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// window.axios.defaults.withCredentials = true;
// window.axios.defaults.withXSRFToken = true;

import jQuery from 'jquery';
window.$ = jQuery;

import DataTable from 'datatables.net-dt';
import 'datatables.net-scroller-dt';
window.DataTable = DataTable;
