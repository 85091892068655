import './bootstrap';
import.meta.glob([
    '../images/**',
    '../fonts/**',
]);
import Chart from 'chart.js/auto';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

/**
 * SCRIPTS
 */
window.makeProgressChart = function (completionPercentage, completionRemainder) {
    const ctx = document.getElementById('the-pie');

    new Chart(ctx, {
        type: 'pie',
        data: {
            labels: ['Completed', 'Remaining'],
            datasets: [{
                label: '',
                data: [completionPercentage, completionRemainder],
                backgroundColor: ["#06455d", "#d8e9f0"],
                hoverBackgroundColor: ["#06455d", "#d8e9f0"],
                hoverBorderColor: ["#06455d", "#d8e9f0"],
                borderColor: ["#06455d", "#d8e9f0"],
                borderWidth: [0, 0],
                hoverBorderWidth: [0, 0],
            }]
        },
        options: {
            plugins: {
                legend: {
                    display: false,
                },
            },
        },
    });
};

$('.modal-close').on("click", function (event) {
    event.preventDefault();
    $('.modal-container').addClass("hide");
});

$('#test-confirm-submit-button').on("click", (event) => {
    event.preventDefault();
    $('.modal-container').removeClass("hide");
});

window.setCookie = function (name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
};

window.getCookie = function (name) {
    const regex = new RegExp(`(^| )${name}=([^;]+)`)
    const match = document.cookie.match(regex)
    if (match) {
        return match[2]
    }
};

/**
 * TIMERS
 */
var testTimerStartTime;

window.setTestTimerStart = function (time) {
    testTimerStartTime = time;
};

window.testTimer = function () {
    var timer = testTimerStartTime.split(':');
    var minutes = parseInt(timer[0], 10);
    var seconds = parseInt(timer[1], 10);
    var current_time_interval = 0;
    var test_required_minutes = parseInt($('#required_minutes').val());
    --seconds;
    minutes = (seconds < 0) ? --minutes : minutes;
    seconds = (seconds < 0) ? 59 : seconds;
    seconds = (seconds < 10) ? '0' + seconds : seconds;
    if (minutes < 0) {
        $('.time').html('0:00 min');
    } else {
        $('.time').html(minutes + ':' + seconds + ' min');
    }
    current_time_interval = (test_required_minutes - minutes) - 1;

    if (current_time_interval > parseInt($('#current_time_interval').val())) {
        $('#current_time_interval').val(current_time_interval).trigger('change');
    }
    testTimerStartTime = minutes + ':' + seconds;
};

window.allTestQuestionsAnswered = (function () {
    if ($('input[type="radio"]:checked').length > 0) {
        if ($('input[type="radio"]:checked').length === $('input[type="radio"]').length) {
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }
})();

// window.checkEnableSubmit = function (timeInterval, requiredMinutes) {
//     if (parseInt(timeInterval) === parseInt(requiredMinutes) && allTestQuestionsAnswered) {
//         return true;
//     } else {
//         return false;
//     }
// };


// var braintree = require('braintree-web');
// braintree.setup('CLIENT-TOKEN-FROM-SERVER', 'INTEGRATION-TYPE', options);